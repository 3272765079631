
export const baseURL = new URL("https://www.twistedtech.wtf/");

export const pathBUBO = "/bubo";
export const pathNOFS = "/nofs";
export const pathTUNE = "/launchpad/tune";

export const urlBUBO = new URL(`${pathBUBO}/`, baseURL);
export const urlNOFS = new URL(`${pathNOFS}/`, baseURL);
export const urlTUNE = new URL(`${pathTUNE}/`, baseURL);
// note: if trying to use these against other locally hosted urls, drop the "s" of https
// when you get that error that makes no sense.