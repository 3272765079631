import React from "react";
import { Socials } from "./Socials";
import { urlBUBO, urlNOFS, urlTUNE } from "../common/configs";
import { randomDisappointment } from "./functions";

import bubonic from "../resources/images/BLOOD_official.png";
import nofs from "../resources/images/NOFS_pumpkin.gif";
import tune from "../resources/images/TUNE_card.png";
import kid from "../resources/images/ghost_kid.gif";
import needle from "../resources/images/needle.gif";
import potion from "../resources/images/potion.gif";

export function App(): React.ReactElement {

  return(
    <div className="splash-page container">
    <div className="header" >
      Welcome to the world of Twisted Tech productions!
    </div>
    <div className="main-projects">
      <div className="nav-div"> 
        <img
          className="project-image"
          id="bubonic"
          alt="bubonic-icon"
          src={bubonic}
        />
        <a 
          href={urlBUBO.toString()}
          className="header-link-button bubos-link"
        >
          Bubonic Bastards
        </a>

      </div>
      <div className="nav-div"> 
        <img
            className="project-image"
            id="nofs"
            alt="nofs-icon"
            src={nofs}
          />
        <a 
          className="header-link-button nofs-link"
          href={urlNOFS.toString()}
        >
          Nightmare on Fantom St.
        </a>
      </div>
    </div>
    <div className="sub-title">
      Checkout our launchpad projects too:
    </div>
    <div className="launchpad-projects">
      <div className="nav-div"> 
        <img
          className="project-image"
          id="tune"
          alt="tune-icon"
          src={tune}
        />
        <a
          className="header-link-button tune-link"
          href={urlTUNE.toString()}
        >
          Tune
        </a>

      </div>
    </div>
    <img
        onClick={ randomDisappointment }
        className="ghost-kid"
        id="ghost-kid"
        alt="ghost-kid"
        src={kid}
      />
    <img
      className="needle-gif"
      id="needle-gif"
      alt="needle-gif"
      src={needle}
      style={{
        zIndex: 100,
      }}
    /> 
    <img
      className="potion-gif"
      id="potion-gif"
      alt="potion-gif"
      src={potion}
      style={{
        zIndex: 100,
      }}
    /> 
    <div className="footer" >
      <div className="company">
        Twisted Tech
      </div>
      <Socials />
    </div>
  </div>
  )
}